<template>
  <v-card class="ma-3">
    <v-tabs v-model="tab" bg-color="deep-purple-accent-4" centered stacked>
      <v-tab value="tab-1">
        <v-icon>mdi-launch</v-icon>
        Lançamentos
      </v-tab>

      <v-tab value="tab-2">
        <v-icon>mdi-view-dashboard</v-icon>
        Dashboard
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item>
        <base-loading v-if="loading.list" />
        <crud-list
          v-else
          v-model="list"
          :headers="headers"
          :actions="actions"
          :slots="[
            'item.total',
            'item.debito',
            'item.dataAtendimento',
            'item.combustivel',
            'item.alimentacao',
            'item.hospedagem',
            'item.pedagio',
          ]"
        >
          <template
            v-if="
              $user.get().role === 'consultor' || $user.get().role === 'admin'
            "
            v-slot:btnCadastro
          >
            <v-row>
              <v-col cols="12" sm="6" md="auto" class="col-xs-2 py-md-0">
                <router-link :to="`financeiro/create`">
                  <v-btn color="success"> Novo lançamento </v-btn>
                </router-link>
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`item.total`]="{ item }">
            {{ item.total ? 'R$ ' + currencyFormatter(item.total) : ' - ' }}
          </template>
          <template v-slot:[`item.debito`]="{ item }">
            {{ item.debito ? 'R$ ' + currencyFormatter(item.debito) : ' - ' }}
          </template>
          <template v-slot:[`item.dataAtendimento`]="{ item }">
            {{
              item.dataAtendimento
                ? moment(item.dataAtendimento).format('DD/MM/YYYY')
                : ' - '
            }}
          </template>
          <template v-slot:[`item.combustivel`]="{ item }">
            {{
              item.combustivel
                ? 'R$ ' + currencyFormatter(item.combustivel)
                : ' - '
            }}
          </template>
          <template v-slot:[`item.alimentacao`]="{ item }">
            {{
              item.alimentacao
                ? 'R$ ' + currencyFormatter(item.alimentacao)
                : ' - '
            }}
          </template>
          <template v-slot:[`item.hospedagem`]="{ item }">
            {{
              item.hospedagem
                ? 'R$ ' + currencyFormatter(item.hospedagem)
                : ' - '
            }}
          </template>
          <template v-slot:[`item.pedagio`]="{ item }">
            {{ item.pedagio ? 'R$ ' + currencyFormatter(item.pedagio) : ' - ' }}
          </template>
        </crud-list>
      </v-tab-item>

      <v-tab-item>
        <Dashboard />
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import consultantServices from '../../../services/api/consultant/index'
import { currencyFormatter } from '../../../utils/formatter'
import Dashboard from '../dashboard/index.vue'

export default {
  components: { Dashboard },
  data() {
    return {
      loading: {
        list: false,
      },

      tab: 0,

      actions: [
        {
          title: 'Editar lançamento',
          color: 'yellow darken-3',

          click: item =>
            this.$router.push({
              path: 'financeiro/create',
              query: {
                id: item.id,
              },
            }),

          icon: 'mdi-pencil',
        },
        {
          title: 'Excluir lançamento',
          color: 'red darken-3',
          click: item => this.delete(item),
          icon: 'mdi-delete',
        },
      ],

      headers: [
        {
          text: '',
          sortable: false,
          width: '40px',
          value: 'actions',
          align: 'left',
        },

        {
          text: 'Gastos totais',
          align: 'left',
          sortable: true,
          value: 'total',
          width: 'auto',
          filterable: true,
        },
        {
          text: 'Debitos totais',
          align: 'left',
          sortable: true,
          value: 'debito',
          width: 'auto',
          filterable: true,
        },
        {
          text: 'Número da nota',
          align: 'left',
          sortable: true,
          value: 'numeroNota',
          width: '10',
          filterable: true,
        },
        {
          text: 'Data da nota',
          align: 'left',
          sortable: true,
          value: 'launch',
          width: 'auto',
          filterable: true,
        },
        {
          text: 'Combustível',
          align: 'left',
          sortable: true,
          value: 'combustivel',
          width: 'auto',
          filterable: true,
        },
        {
          text: 'Alimentação',
          align: 'left',
          sortable: true,
          value: 'alimentacao',
          width: 'auto',
          filterable: true,
        },
        {
          text: 'Hospedagem',
          align: 'left',
          sortable: true,
          value: 'hospedagem',
          width: 'auto',
          filterable: true,
        },
        {
          text: 'Pedágio',
          align: 'left',
          sortable: true,
          value: 'pedagio',
          width: 'auto',
          filterable: true,
        },
      ],

      list: [],
    }
  },

  async created() {
    await this.getFinancialConsultor()
  },

  methods: {
    currencyFormatter,
    async getFinancialConsultor() {
      this.loading.list = true
      // const reports = await reportService.list({ id: null })
      const result = await consultantServices.financeiro.list()

      let normalized = result.data.map(item => ({
        ...item,
        total:
          item.alimentacao +
          item.combustivel +
          item.hospedagem +
          item.pedagio +
          item.fees +
          item.healthInsurance +
          item.lifeInsurance +
          item.otherValues,

        debito:
          item.unitedBreakdowns +
          item.fineLateData +
          item.driverFines +
          item.postgraduate +
          item.purchasing +
          item.otherDiscounts,
      }))

      normalized = normalized.sort((a, b) => {
        return new Date(b.launch) - new Date(a.launch)
      })

      this.list = normalized
      this.loading.list = false
    },

    async delete(item) {
      this.Swal.fire({
        title: 'Tem certeza que deseja excluir este lançamento?',
        text: 'Você não poderá desfazer esta ação!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim, excluir!',
        cancelButtonText: 'Cancelar',
      }).then(async res => {
        if (res.value) {
          try {
            const result = await consultantServices.financeiro.delete({
              id: item.id,
            })

            if (result.success) {
              this.list = this.list.filter(i => i.id !== item.id)

              this.Toast().fire({
                icon: 'success',
                title: 'Lançamento financeiro foi deletado com sucesso!',
              })
            } else {
              this.Swal.fire({
                title: 'Erro!',
                text: 'Não foi possível excluir o lançamento!',
                icon: 'error',
                confirmButtonText: 'Ok',
              })
            }
          } catch (error) {
            this.Swal.fire({
              title: 'Erro!',
              text: 'Não foi possível excluir o lançamento!',
              icon: 'error',
              confirmButtonText: 'Ok',
            })
          }
        }
      })
    },
  },
}
</script>
