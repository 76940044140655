<template>
  <v-container>
    <Filtro @search="getLaunch" />
    <v-row>
      <v-col cols="12">
        <base-loading v-if="data.length <= 0" />
      </v-col>
      <v-col cols="12">
        <Tables
          v-if="data.length >= 1"
          :data="data"
          :consultor="$user.get().name"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FinancialService from '../../../services/api/financial'
import Filtro from './filter.vue'
import Tables from './tables.vue'

export default {
  name: 'Dashboard',

  components: {
    Tables,
    Filtro,
  },

  data: () => ({
    data: [],
  }),

  mounted() {},

  created() {
    this.getLaunch({
      dataInicio: this.moment()
        .subtract(1, 'months')
        .toISOString(true)
        .substr(0, 10),
      dataFim: this.moment().toISOString(true).substr(0, 10),
    })
  },

  methods: {
    async getLaunch(form) {
      const { dataInicio, dataFim } = form

      this.data = []
      const { data } = await FinancialService.list({
        DataFim: dataFim,
        DataInicio: dataInicio,
      })

      this.data = data
    },
  },
}
</script>
