<template>
  <div>
    <base-loading v-if="loading.filter" />
    <v-row v-else align="center" justify="center" class="px-10">
      <v-col class="pa-1" cols="2" sm="2" md="2">
        <v-menu
          v-model="menuDataIni"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              :value="moment(form.dataInicio).format('DD/MM/YYYY')"
              label="Data Inicial"
              prepend-icon="mdi-calendar"
              color="teal"
              readonly
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="form.dataInicio"
            style="margin: 0px"
            color="teal"
            locale="pt-br"
            @input="menuDataIni = false"
          />
        </v-menu>
      </v-col>
      <v-col class="pa-1" cols="2" sm="2" md="2">
        <v-menu
          v-model="menuDataFim"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              :value="moment(form.dataFim).format('DD/MM/YYYY')"
              color="teal"
              label="Data Final"
              prepend-icon="mdi-calendar"
              readonly
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="form.dataFim"
            :min="form.dataInicio"
            :max="dataLimite"
            color="teal"
            locale="pt-br"
            @input="menuDataFim = false"
          />
        </v-menu>
      </v-col>

      <v-col class="pa-2" cols="2" sm="2" md="2">
        <v-row justify="end" align="center">
          <v-btn
            style="color: white; border-radius: 8px; margin-left: 40%"
            color="green darken-1"
            @click="search"
          >
            <span style="font-size: 16px; font-weight: 600">Buscar</span>
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'DashboardFilter',

  emits: ['search'],

  data() {
    return {
      loading: {
        filter: false,
      },

      searching: true,
      dataLimite: this.moment(this.dataInicio).add(0, 'months').toISOString(),
      menuDataIni: false,
      menuDataFim: false,

      form: {
        dataInicio: this.moment()
          .subtract(1, 'months')
          .toISOString(true)
          .substr(0, 10),
        dataFim: this.moment().toISOString(true).substr(0, 10),
      },
    }
  },

  created() {},

  methods: {
    search() {
      this.$emit('search', this.form)
    },
  },
}
</script>
